exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autores-contentful-author-url-js": () => import("./../../../src/pages/autores/{contentfulAuthor.url}.js" /* webpackChunkName: "component---src-pages-autores-contentful-author-url-js" */),
  "component---src-pages-blog-contentful-post-url-js": () => import("./../../../src/pages/blog/{contentfulPost.url}.js" /* webpackChunkName: "component---src-pages-blog-contentful-post-url-js" */),
  "component---src-pages-calculadora-de-sueldo-neto-js": () => import("./../../../src/pages/calculadora-de-sueldo-neto.js" /* webpackChunkName: "component---src-pages-calculadora-de-sueldo-neto-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contentful-page-url-js": () => import("./../../../src/pages/{contentfulPage.url}.js" /* webpackChunkName: "component---src-pages-contentful-page-url-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recursos-contentful-resource-url-js": () => import("./../../../src/pages/recursos/{contentfulResource.url}.js" /* webpackChunkName: "component---src-pages-recursos-contentful-resource-url-js" */)
}

